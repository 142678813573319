<template>
  <div class="flex" v-if="!this.noFields || this.noFields==undefined">
        <span class="p-input-icon-right mr-1" style="width: 35%;">
            <i class="pi pi-search" @click="abrirFiltroAvancado()" v-if="!disabled" />
            <InputText
                :disabled="disabled"
                :class="{ 'p-invalid': invalid }"
                class="w-full"
                placeholder="Código"
                v-model="selecionado.codigoErp"
                autocomplete="off"
                @keyup.enter="selecionar"
                @change="selecionar"
            />
        </span>

    <InputText
        :disabled="true"
        :class="{ 'p-invalid': invalid }"
        style="width: 65%;"
        v-model="selecionado.nomeFantasia"
        :title="selecionado.nomeFantasia"
        placeholder="Nome"
    />
  </div>

  <div class="flex" v-if="this.noFields">
        <span class="p-input-icon-right mr-1" style="width: 35%;">
            <InputText hidden
                :disabled="disabled"
                :class="{ 'p-invalid': invalid }"
                class="w-full"
                placeholder="Código"
                v-model="selecionado.codigoErp"
                autocomplete="off"
                @keyup.enter="selecionar"
                @change="selecionar"
            />
        </span>

    <InputText hidden
        :disabled="true"
        :class="{ 'p-invalid': invalid }"
        style="width: 65%;"
        v-model="selecionado.nomeFantasia"
        :title="selecionado.nomeFantasia"
        placeholder="Nome"
    />
  </div>

  <Dialog modal v-model:visible="filtroAvancado"  :style="{width: '50%'}" @show="pesquisar">

    <template #header>
      <span v-if="!tituloModal" class="p-dialog-title">Buscar</span>
      <span v-if="!!tituloModal" class="p-dialog-title">{{ tituloModal }}</span>
    </template>

    <DataTable
        @sort="sort($event)"
        showGridlines
        selectionMode="single"
        responsiveLayout="stack"
        :value="dados.content"
        v-model:selection="transportadora"
        @row-select="selecionar">

      <template #header>
        <div class="grid p-fluid formgrid align-items-center">
          <div class="col-12 lg:col-2">
            <div class="field">
              <label for="codigo"><strong>Código</strong></label>
              <InputText id="codigo" class="w-full" v-model="filtros.codigoErp" @keyup.enter="pesquisar" />
            </div>
          </div>

          <div class="col-12 lg:col-2">
            <div class="field">
              <label for="cnpj"><strong>CNPJ</strong></label>
              <InputText id="cnpj" class="w-full" v-model="filtros.cnpj" @keyup.enter="pesquisar" />
            </div>
          </div>

          <div class="col-12 lg:col-4">
            <div class="field">
              <label for="nome"><strong>Nome</strong></label>
              <InputText id="nome" class="w-full" v-model="filtros.nome" @keyup.enter="pesquisar" />
            </div>
          </div>

          <div class="mr-2 mt-2">
            <div class="p-inputgroup">
              <Button
                  label="Filtrar"
                  icon="pi pi-filter"
                  class="p-button-outlined p-button-info"
                  @click="pesquisar"
              ></Button>

              <Button
                  title="Limpar Filtro"
                  icon="pi pi-filter-slash"
                  class="p-button-outlined p-button-info"
                  style="margin-left: 1px;"
                  @click="limparFiltro"
              ></Button>
            </div>
          </div>
        </div>
      </template>

      <template #empty>
        Nenhum registro encontrado.
      </template>

      <template #loading>
        Carregando... Por favor, aguarde.
      </template>

      <Column field="id" header="Id" style="padding-top: 10px; padding-bottom: 10px;" sortable/>
      <Column field="codigoErp" header="Código" sortable/>
      <Column field="cnpj" header="Cnpj" sortable/>
      <Column field="nomeFantasia" header="Nome" sortable/>
    </DataTable>

    <Paginator
        :rows="filtros.size"
        :totalRecords="totalRegistro"
        :rowsPerPageOptions="[1, 5, 10]"
        @page="onPage"/>
  </Dialog>
</template>

<script>
export default {
  emits: ["pesquisar", "selecionado"],
  props: {
    campoObrigatorio: {
      type: Boolean,
      required: false
    },
    selecionado: {
      type: Object,
      required: true
    },
    paginacao: {
      type: Object,
      required: true
    },
    pagina: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    invalid: {
      type: Boolean,
      required: false
    },
    tituloModal: {
      type: String,
      required: false
    },
    noFields: {
      type: Boolean,
      required: true
    }
  },
  watch:{
    paginacao:function(value) {
      if (!!value) {
        this.dados = value;
      }
    },
    pagina:function(value){
      if(!!value){
        this.dados = value;
        this.abrirFiltroAvancado();
      }
    }
  },

  data() {
    return {
      filtros: {codigoErp:null, cnpj:null, nome:null},
      totalRegistro: 0,
      filtroAvancado: false,
      transportadora: null,
      dados: []
    }
  },

  updated() {
    if (!!this.dados.pageable && !!this.dados.totalPages) {
      const qtdRegistros = this.dados.pageable.pageSize;
      this.totalRegistro = this.dados.totalPages * qtdRegistros;
    }
  },

  methods: {
    pesquisar() {
      this.$emit("pesquisar", this.filtros, true);
    },

    selecionar() {
      const codigoErp = !!this.transportadora ? this.transportadora.codigoErp : this.selecionado.codigoErp;
      let transportadora = null;

      if (!!this.transportadora) {
        transportadora = Object.assign({}, this.transportadora);
      }

      this.$emit('selecionado', codigoErp, transportadora);
      this.fecharFiltroAvancado();
    },

    abrirFiltroAvancado() {
      this.filtros = {};
      this.filtros["size"] = 10;
      this.filtros["page"] = 1;
      this.filtros["sort"] = 'codigoErp,asc';

      //this.pesquisar();

      this.filtroAvancado = true;
    },

    fecharFiltroAvancado() {
      this.transportadora = null;
      this.filtroAvancado = false;
    },

    limparFiltro() {
      this.filtros["codigoErp"] = null;
      this.filtros["cnpj"] = null;
      this.filtros["nome"] = null;

      this.pesquisar();
    },

    onPage(event) {
      this.filtros["page"] = event.page + 1;
      this.filtros["size"] = event.rows;
      this.pesquisar();
    },

    sort(event) {
      const sortField = event.sortField;
      const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
      this.filtros["sort"] = `${sortField},${sortOrder}`;
      this.pesquisar();
    }
  }
}
</script>