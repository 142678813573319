import axios from "axios";
import ApiUrl from "../config/ApiUrl";

class TransportadoraService {

    salvar(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }
        return axios.post(`${  ApiUrl.vg  }api/genesis-vg/v1/transportadora`, dados, config);
    }

    buscarPorId(id) {
        return axios.get(`${ApiUrl.vg}api/genesis-vg/v1/transportadora/${id}`);
    }

    buscarPorCodigo(codigo) {
        return axios.get(`${ApiUrl.vg}api/genesis-vg/v1/transportadora/por-codigo/${codigo}`);
    }

    getPorDescricao(descricao) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/transportadora/buscar-por-descricao/${ descricao }`);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10, sort: 'id,asc' }) {
        return axios.get(`${ApiUrl.vg}api/genesis-vg/v1/transportadora/buscar-com-paginacao`, { params });
    }
 
    getPorFiltro(descricao) {
        var params = {descricao: descricao};
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/transportadora/por-filtro`, { params });
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/transportadora/inativar/${ id }`);
    }
    
    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/transportadora/ativar/${ id }`);
    }

}

export default new TransportadoraService();
