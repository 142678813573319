import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class SolicitacaoEmbarqueService {
    salvar(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/solicitacao-embarque`, dados, config);
    }

    buscarPorId(idEmbarque) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/solicitacao-embarque/${ idEmbarque }`);
    }

    listarPedidosSugeridos(params) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/solicitacao-embarque/pedidos-sugeridos`, { params });
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/solicitacao-embarque/buscar-com-paginacao`, { params });
    }

    buscarEmpresas(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/solicitacao-embarque/buscar-empresas`, { params });
    }

    buscarJustificativas() {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/solicitacao-embarque/justificativas-cancelamento-transportadora`);
    }

    buscarComEmbarquePendente(codigoEstabelecimento, municipioId, terminalId) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/empresa-compra-venda/clientes-com-embarque-pendente`, {
            params: {
                codigoEstabelecimento, 
                municipioId, 
                terminalId
            }
        });
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/solicitacao-embarque/inativar/${ id }`);
    }
    
    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/solicitacao-embarque/ativar/${ id }`);
    }

    cancelarAutorizacao(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }
        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/solicitacao-embarque/cancelar-autorizacao-transportadora`,dados, config);
    }

}

export default new SolicitacaoEmbarqueService();