<template>
    <div class="card" v-if="this.tela!='embarque'">
        <div class="flex align-items-center">
            <i class="pi pi-building" style="font-size: 1.4rem"></i>
            <h3 style="margin:0px 5px;">Transportadoras</h3>
        </div>

        <hr />

        <DataTable responsiveLayout="stack" :value="data" v-model:selection="transportadoraSelecionada"
            selectionMode="single" showGridlines @sort="sort($event)">

            <template #header>
                <div class="flex align-items-center">
                    <div class="field mr-2">
                        <label>
                            <strong>Nome</strong>
                        </label>

                        <InputText class="w-full" v-model="filter.nome" />
                    </div>
                    <div class="field mr-2">
                        <label>
                            <strong>CNPJ</strong>
                        </label>

                        <InputText class="w-full" v-model="filter.cnpj" />
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button label="Filtrar" icon="pi pi-filter" class="p-button-outlined p-button-info"
                                @click="carregarDados()"></Button>

                            <Button title="Limpar Filtro" icon="pi pi-filter-slash" class="p-button-outlined p-button-info"
                                style="margin-left: 1px;" @click="limparFiltro()"></Button>
                        </div>
                    </div>

                    <Button v-if="filter.situacao == 'ATIVO' && integration.visible" type="button" icon="pi pi-ban" label="Listar inativos"
                        class="p-button-outlined p-button-danger mr-2 mt-2"
                        @click="filter.situacao = 'INATIVO'; carregarDados()"></Button>

                    <Button v-if="filter.situacao == 'INATIVO' && integration.visible" type="button" icon="pi pi-check-circle"
                        label="Listar ativos" class="p-button-outlined mr-2 mt-2"
                        @click="filter.situacao = 'ATIVO'; carregarDados()"></Button>

                    <Button v-if="integration.visible" label="Cadastrar" icon="pi pi-plus" class="p-button-info mt-2 mr-2"
                        @click="cadastrar()"></Button>

                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="id" header="Código" sortable />
            <Column field="nomeFantasia" header="Nome Fantasia" sortable />
            <Column field="cnpj" header="CNPJ" />
            <Column field="endereco" header="Endereço" />
            <Column field="municipio.nome" header="Município" />
            <Column field="contato" header="Contato" />
            <Column field="telefone" header="Telefone" />

            <Column header="Ações" style="width:9rem;">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button v-if="integration.visible" title="Editar" icon="pi pi-pencil"
                            class="p-button-rounded p-button-info flex-none mr-2" @click="editar(data.id)"></Button>

                        <Button v-if="!integration.visible" title="Visualizar" icon="pi pi-eye"
                            class="p-button-rounded p-button-info flex-none mr-2" @click="editar(data.id)"></Button>

                        <Button title="Visualizar histórico" icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none mr-2"
                            @click="visualizarHistorico(data)"></Button>

                        <Button v-if="data.ativo && integration.visible" title="Inativar" class="p-button-rounded flex-none p-button-danger"
                            icon="pi pi-ban" @click="confirmarAtivarOuInativar(data)"></Button>

                        <Button v-if="!data.ativo  && integration.visible" title="Ativar" class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle" @click="confirmarAtivarOuInativar(data)"></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator v-model:first="primeiraLinha" :rows="qtdRegistroPorPagina" :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]" @page="onPage" />
    </div>

  <div v-if="this.tela=='embarque'">

    <div class="flex align-items-center justify-content-between">
      <div style="width:90%">
      </div>

      <div class="flex align-items-center">
        <Button icon="pi pi-plus" title="Incluir" label="Incluir" @click="carregarTransportadoras"
                class="p-button-outlined p-button-info mr-2" v-if="modo!=undefined" />
      </div>
    </div>

    <br>
    <DataTable responsiveLayout="stack" :value="data" v-model:selection="transportadoraSelecionada"
               selectionMode="single" @sort="sort($event)">

      <template #empty>
        Nenhum registro encontrado.
      </template>

      <template #loading>
        Carregando... Por favor, aguarde.
      </template>

      <Column field="nomeFantasia" header="Descrição" sortable>
        <template #body="{data}">
            {{data.codigoErp}} - {{data.nomeFantasia}}
        </template>
      </Column>
      <Column field="cnpj" header="CNPJ" />
      <Column field="contato" header="Contato" />
      <Column field="telefone" header="Telefone" />
      <Column field="status" header="Status" />
      <Column field="justificativaCancelamento" header="Observações" />

      <Column header="Ações" style="width:6rem;" v-if="tela=='embarque' && modo==undefined && !isOperacao">
        <template #body="{ data, index }">
          <Button :disabled="data.status=='Cancelado'" class="p-button-rounded p-button-info mr-2 mb-2" type="button" icon="pi pi-ellipsis-v" @click="toggle($event,data,index)" aria-haspopup="true" aria-controls="overlay_menu" />
          <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
        </template>
      </Column>
      <Column v-if="tela=='embarque' && modo=='Nova'" header="Ação" style="width:1rem" class="centralizar-titulo-tabela" >
        <template #body="{ data, index }">
          <Button class="p-button-rounded p-button-info mr-2 mb-2" icon="pi pi-trash"
                  style="width:1.3rem; height:1.3rem" @click="excluirTransportadora(index)" title="Excluir"></Button>
        </template>
      </Column>
      <Column v-if="tela=='embarque' && modo=='Editar'" header="Ação" style="width:1rem" class="centralizar-titulo-tabela" frozen alignFrozen="right">
        <template #body="{ data, index }">
          <Button class="p-button-rounded p-button-info mr-2 mb-2" icon="pi pi-trash"
                  style="width:1.3rem; height:1.3rem" @click="excluirTransportadora(index)" title="Excluir" v-if="!transportadoras[index].blocked"></Button>
        </template>
      </Column>

    </DataTable>

    <Paginator v-model:first="primeiraLinha" :rows="qtdRegistroPorPagina" :total-records="totalRegistro" v-if="this.tela!='embarque'"
               :rowsPerPageOptions="[5, 10, 20, 30]" @page="onPage" />
  </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{ width: '300px' }" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Tem certeza que deseja <strong>{{ transportadoraSelecionada.ativo ? 'inativar' : 'ativar' }}</strong> a Transportadora:
                <strong>{{ transportadoraSelecionada.id }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <ModalCancelamentoAutorizacaoEmbarque
        :solicitacaoEmbarqueTransportadora="transportadoraSelecionada"
        :solicitacaoEmbarque="solicitacaoEmbarque"
        :visivel="cancelamentoAutorizacaoDialog"
        @fechar="fechar"
        @cancelarAutorizacao="cancelarAutorizacao"
    />

    <TransportadoraZoom :pagina="listaTransportadoras"
                        :selecionado="transpSel"
                        :campoObrigatorio="true"
                        :noFields="true"
                        @pesquisar="carregarTransportadoras"
                        @selecionado="adicionarTransportadora"
    />
</template>

<script>
import Formatacao from '../../utilities/Formatacao';
import StorageService from '../../service/StorageService';
import TransportadoraService from '../../service/TransportadoraService';
import ModalHistoricoGenerico from '../ModalHistoricoGenerico.vue';
import IntegracaoService from '../../service/IntegracaoService';
import ModalCancelamentoAutorizacaoEmbarque from "@/components/cadastro/embarque/ModalCancelamentoAutorizacaoEmbarque.vue";
import TransportadoraZoom from "@/components/zoom/TransportadoraZoom.vue";
import SolicitacaoEmbarqueService from "@/service/SolicitacaoEmbarqueService";
import {ref} from "vue";

export default {
    name: 'TransportadoraList',
    components: { ModalHistoricoGenerico,ModalCancelamentoAutorizacaoEmbarque,TransportadoraZoom },
    props: {
      tela: {type: String, required:true},
      transportadoras: {type: Object, required: true},
      solicitacaoEmbarque: {type: Object, required: true},
      modo: {type: String, required: true},
      selectedItem: { type: Object }
    },

  watch: {
    transportadoras:function(t) {
      if(this.tela=='embarque') {
        this.carregarDados();
      }
    },
    selectedItem(t){
      this.transportadoraSelecionada = t;
    }
  },

    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,
            data: [],
            filter: {},
            ordenar: 'id,asc',
            ativarOuInativarDialog: false,
            transportadoraSelecionada: {},
            transpSel: {codigoErp: null},
            historicoDialog: false,
            descricaoHistoricoDialog: null,
            integration: {},
            storage: StorageService.getControlePagina("TRANSPORTADORA"),
            storangeMessage: StorageService.getMensagemToast("TRANSPORTADORA"),
            items: ref([
              {
                label: 'Escolha...',
                items: [
                  {label:'Cancelar Autorização',icon:'pi pi-trash',command: () => this.cancelarAutorizacao(this.selectedItem) }
                ]
              }
            ]),
            checkboxValue: [],
            cancelamentoAutorizacaoDialog: false,
            listaTransportadoras: [],
            solicitacaoEmbarque: {},
            isAdministracao: false,
            isLogistica: false,
            isOperacao: false
        }
    },

    created() {
        this.isAdministracao = this.$store.state.auth.user.isAdministracao;
        this.isLogistica = this.$store.state.auth.user.isLogistica;
        this.isOperacao = this.$store.state.auth.user.isOperacao;

        this.filter.situacao = "ATIVO";

        if (this.storage.default == undefined) {
            this.filter = this.storage.filtros;
        }

        this.pagina = this.storage.numero;
        this.primeiraLinha = this.storage.firstRow;
        this.qtdRegistroPorPagina = this.storage.qtdRegistro;
        this.getIntegrationOptons();
        if(this.tela!='embarque' || this.modo==undefined) {this.carregarDados();}
        this.exibirMensagemToast();
    },

    methods: {
        exibirMensagemToast() {
            if (this.storangeMessage.active) {
                this.$toast.add({
                    severity: this.storangeMessage.severity,
                    summary: this.storangeMessage.summary,
                    detail: this.storangeMessage.detail,
                    life: 7500
                });
                StorageService.setMensagemToast("TRANSPORTADORA", 'info', '', '', false);
            }
        },
        
        cadastrar() {
            this.$router.push(`/transportadora/cadastro`);
        },

        cancelarAutorizacao(linha){
          SolicitacaoEmbarqueService.buscarPorId(linha.solicitacaoEmbarqueId)
              .then(({data}) => {
                this.solicitacaoEmbarque = data;
                this.cancelamentoAutorizacaoDialog = true;

              });

        },

        fechar(){
          this.cancelamentoAutorizacaoDialog = false;
          this.$emit("fechar",true)
          this.$emit("atualizar");
        },

        editar(transportadoraId) {
            this.$router.push(`/transportadora/editar/${transportadoraId}`);
        },

        carregarDados() {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;

            if(this.tela=='embarque'){
              this.$data.data = this.transportadoras;
              this.transportadoraSelecionada = this.transportadoras[0];
              return;
            }
            TransportadoraService.buscarComPaginacao(this.filter)
                .then(({ data }) => {
                    if (data) {
                        this.data = data.content;
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao consultar Transportadoras',
                        detail: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
                        life: 7500
                    };

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });

            StorageService.setControlePaginaFilter("TRANSPORTADORA", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);
        },

        carregarTransportadoras(filtros) {
          this.filter["page"] = this.pagina;
          this.filter["size"] = this.qtdRegistroPorPagina;
          this.filter["sort"] = this.ordenar;
          this.filter["codigoErp"] = filtros.codigoErp;
          this.filter["nome"] = filtros.nome;
          this.filter["cnpj"] = filtros.cnpj;

          TransportadoraService.buscarComPaginacao(this.filter)
              .then(({ data }) => {
                if (!!data) {
                  if(this.$data.data.length>0){
                  let lista = [];
                  for(let listaTransportadoras of data.content) {
                    let achou = false;
                    for(let l of this.$data.data){
                      if(l.transportadoraId == listaTransportadoras.id ||
                        l.id == listaTransportadoras.id){
                        achou = true;
                        break;
                      }
                    }
                    if(!achou) {lista.push(listaTransportadoras)}
                  }
                  data.content = lista;
                  }
                  this.listaTransportadoras = data;
                } else {
                  this.listaTransportadoras = [];
                }
              })
              .catch(error => {
                this.listaTransportadoras = [];
                const toast = {
                  severity: 'error',
                  summary: 'Falha ao consultar Transportadoras',
                  detail: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
                  life: 7500
                };

                if (!!error && !!error.response && !!error.response.data) {
                  const { message } = error.response.data;
                  toast["detail"] = message;
                }

                this.$toast.add(toast);
              });

        },

        adicionarTransportadora(codigoErp, transp){
          let t = [];
          if (this.$data.data.length>0) {
            t = this.$data.data;
          }
          t.push(transp);
          this.data = Object.assign([], t);
          this.transportadoras["retorno"] = Object.assign([],t);
          },

        excluirTransportadora(indice){
          this.$data.data.splice(indice,1);
          this.transportadoras["retorno"] = Object.assign([],this.data);
        },

        clickCheck(linha,index){
          this.transportadoras[index].checked = !this.transportadoras[index].checked;
        },

        limparFiltro() {
            this.filter = {};
            this.filter.situacao = "ATIVO";
            this.carregarDados();
        },

        visualizarHistorico(transportadora) {
            this.descricaoHistoricoDialog = "Transportadora: " + transportadora.id;
            this.historicoDialog = true;
            this.transportadoraSelecionada = transportadora;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.transportadoraSelecionada = {};
        },

        confirmarAtivarOuInativar(transportadora) {
            this.transportadoraSelecionada = transportadora;
            this.ativarOuInativarDialog = true;
        },

        ativarOuInativar() {
            if (this.transportadoraSelecionada.ativo) {
                TransportadoraService.inativar(this.transportadoraSelecionada.id)
                    .then(response => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Inativado',
                            detail: `A Transportadora foi inativada com sucesso.`,
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.transportadoraSelecionada = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity: 'error',
                            summary: 'Falha ao inativar',
                            detail: `Falha não mapeada.`,
                            life: 7500
                        };

                        if (!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                TransportadoraService.ativar(this.transportadoraSelecionada.id)
                    .then(response => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Ativada',
                            detail: `A Transportadora foi ativada com sucesso.`,
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.transportadoraSelecionada = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity: 'error',
                            summary: 'Falha ao ativar',
                            detail: `Falha não mapeada.`,
                            life: 7500
                        };

                        if (!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }

            this.ativarOuInativarDialog = false;
        },

        getIntegrationOptons() {
            const paginaAtual = "TRANSPORTADORA";
            IntegracaoService.getIntegrationOptions(paginaAtual)
                .then(({ data }) => {
                    if (data) {
                        this.integration = data;
                    }
                })
                .catch(error => {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'PARÂMETROS DE PÁGINA NÂO DEFINIDOS',
                        detail: 'Você deve cadastrar os parâmetros para esta página no menu lateral em item: Parâmetros de Integração.',
                        life: 10000
                    });
                });
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows;
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${sortField},${sortOrder}`;
            this.carregarDados();
        },
    }
}
</script>
<script setup>
import {ref} from "vue";

const menu = ref();
const selectedItem= ref();

const toggle = (event,data,index) => {
  menu.value.toggle(event);
  selectedItem.value = data;
};

</script>
