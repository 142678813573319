import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class AjusteFreteService {

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/ajuste-frete/buscar-com-paginacao`, { params });
    }

    salvar(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/ajuste-frete/ajustar-frete`, dados, config);
    }

    buscarJustificativas() {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/ajuste-frete/justificativas`);
    }

}

export default new AjusteFreteService();